import React from 'react'
import styled from 'styled-components'

import CalendlyEmbed from './calendlyEmbed'

const FormStyles = styled.form`
  label {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  input,
  textarea {
    background-color: inherit;
    border: 1px solid ${props => props.theme.darkPurple};
    display: block;
    font-size: 1rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
  }
  div + div {
    margin-top: 2rem;
  }
  label[for="email"],
  label[for="message"] {
    &:before {
      content: '*';
      color: ${props => props.theme.darkPurple};
      margin-right: 0.2rem;
      position: absolute;
      left: -1rem;
    }
  }
  input[type="submit"] {
    transform: scale(1);
    transition: ${props => props.theme.transformScale};
    &:hover,
    &:focus {
      transform: scale(1.2);
    }
  }
  p {
    font-size: 0.9rem;
    span {
      color: ${props => props.theme.darkPurple};
      vertical-align: middle;
    }
  }
`



const ContactForm = () => (
  <>
    <FormStyles 
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="true"
      action="POST">
      <input 
        type="bot-field" 
        style={{ 
          visibility: `hidden`, 
          height: 0, 
          margin: 0, 
          padding: 0 
        }} />
      <div>
        <label htmlFor="name">Name:
          <input 
            type="text"
            id="name"
            name="name"
            placeholder="Sam Smith"
          />
        </label>
      </div>
      <div>
        <label htmlFor="email">Email: 
          <input 
            type="email"
            id="email"
            name="email"
            required
            placeholder="sam@example.com" />
        </label>
      </div>
      <div>
        <label htmlFor="message">Message:
          <textarea 
            name="message" 
            id="message" 
            rows="10"
            required
            placeholder="What's on your mind?"></textarea>
        </label>
      </div>
      <div>
        <input type="submit" value="Say Hello!" />
      </div>
      <p><span>*</span> - Required field</p>
    </FormStyles>
    <CalendlyEmbed />
  </>
)

export default ContactForm