import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

import Layout from '../components/layout'
import ContactForm from '../components/contact'
import PaginationContainer from '../components/paginationContainer'
import TagList from '../components/tagList'
import Seo from '../components/seo'
import FeaturedImg from '../components/featuredImage'

import ContentWrapper from '../styles/contentWrapper'
import TitleWrapper from '../styles/titleWrapper'
import OffsetWrapper from '../styles/offsetWrapper'

const PageTemplate = ({ pageContext }) => {
  let next = null
  let prev = null
  let image = null

  if (pageContext.next) {
    next = {
      title: pageContext.next.title,
      route: `/blog/${pageContext.next.slug}`
    }
  }

  if (pageContext.previous) {
    prev = {
      title: pageContext.previous.title,
      route: `/blog/${pageContext.previous.slug}`
    }
  }

  if (pageContext.featuredImage) {
    image = pageContext.featuredImage.fluid.src
  }

  return (
    <>
      <Seo 
        title={pageContext.title} 
        description={documentToPlainTextString(pageContext.excerpt)}
        image={image}
      />
      <Layout>
        <ContentWrapper>
          <TitleWrapper>
            <h1>{pageContext.title}</h1>
            <PaginationContainer next={next} prev={prev} />
          </TitleWrapper>
          <OffsetWrapper>
            {
              // render content from contentful
              documentToReactComponents(pageContext.content)
            }
            {
              // render the contact form only on the contact page.
              pageContext.contact && <ContactForm />
            }
            {
              pageContext.featuredImage && <FeaturedImg img={pageContext.featuredImage} />
            }
          </OffsetWrapper>
          {
            pageContext.tagList && <TagList tagList={pageContext.tagList} />
          }
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default PageTemplate