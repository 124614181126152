import styled from 'styled-components'

const PaginationStyle = styled.div`
  display: none;
  margin-right: 1rem;
  margin-top: 4rem;
  @media screen and (min-width: ${props => props.theme.mobileMinWidth}) {
    display: flex;
    flex-basis: 100px;
    flex-grow: 0;
    justify-content: space-between;
  }
    a[id^='next-'] {
    border-left: 5px solid;
    border-bottom: 5px solid;
    transform: rotateZ(225deg) scale(1);
    width: 20px;
    display: inline-block;
    height: 20px;
    margin-bottom: 8px;
    margin-left: 14px;
    opacity: 0.2;
    position: relative;
    transition: opacity 0.3s ease-in-out, ${props => props.theme.transformScale};
    will-change: opacity, transform;
    /* &:before {
      content: 'Next';
      display: inline-block;
      transform: rotateZ(-225deg);
    } */
    &:after {
      content: '';
      background-color: ${props => `${props.theme.darkPurple}`};
      border: 1px solid ${props => props.theme.darkPurple};
      border-radius: 50%;
      margin-right: 0.5rem;
      position: absolute;
      top: 0;
      left: 5px;
      height: 10px;
      width: 10px;
    }
    &:hover,
    &:focus {
      opacity: 1;
      transform: rotateZ(225deg) scale(1.2);
    }
  }
  a[id^='prev-'] {
    border-left: 5px solid;
    border-bottom: 5px solid;
    opacity: 0.2;
    transform: rotateZ(45deg) scale(1);
    transition: opacity 0.3s ease-in-out, ${props => props.theme.transformScale};
    will-change: opacity, transform;
    width: 20px;
    display: inline-block;
    height: 20px;
    &:after {
      content: '';
      background-color: ${props => `${props.theme.darkPurple}`};
      border: 1px solid ${props => props.theme.darkPurple};
      border-radius: 50%;
      margin-right: 0.5rem;
      position: absolute;
      top: 0;
      left: 5px;
      transition: opacity 0.3s ease-in-out;
      height: 10px;
      width: 10px;
    }
    &:hover,
    &:focus {
      opacity: 1;
      transform: rotateZ(45deg) scale(1.2);
    }
  }
`

export default PaginationStyle