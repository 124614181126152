import React from  'react'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

const FeaturedImg = ({ img }) => (
  <>
    <Helmet meta={
      [
        {
          property: `og:image:alt`,
          content: `${img.altText} - photo by ${img.attribution}`
        },
        {
          property: `og:image:url`,
          content: `${img.attributionLink}`
        }
      ]
    } />
    <Img 
      fluid={img.fluid}
      alt={img.altText}
    />
  </>
)

export default FeaturedImg