import React from 'react'
import Helmet from 'react-helmet'

const CalendlyEmbed = () => (
  <>
    <Helmet>
      <script defer type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
    </Helmet> 
    <div className="calendly-inline-widget" data-url="https://calendly.com/igarber/phone-call-under-15-minutes?hide_landing_page_details=1" style={{ minMidth: `320px`, height: `630px` }}></div>
  </>
)

export default CalendlyEmbed