import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'

const TagsWrapper = styled.div`
  display: flex;
  flex-basis: 100vw;
  p {
    padding-top: 1rem;
  }
  ul {
    list-style-type: none;
    a {
      background-image: unset;
      color: inherit;
      text-decoration: none;
      align-items: center;
      display: flex;
      padding: 1rem 0;
      position: relative;
      transform: scale(1);
      transition: ${props => props.theme.transformScale};
      will-change: transform;
      &:before {
        content: '';
        background-color: ${props => `${props.theme.darkPurple}`};
        border: 1px solid ${props => props.theme.darkPurple};
        border-radius: 50%;
        margin-right: 0.5rem;
        opacity: 0.2;
        transition: opacity 0.3s ease-in-out;
        height: 10px;
        width: 10px;
      }
      &:hover,
      &:focus {
        transform: scale(1.05);
        &:before {
          content: '';
          background-color: ${props => props.theme.darkPurple};
          border: 1px solid ${props => props.theme.darkPurple};
          border-radius: 50%;
          margin-right: 0.5rem;
          opacity: 1;
          height: 10px;
          width: 10px;
        }
      }
    }
  }
`

const TagList = ({ tagList }) => {
  
  const tagItems = tagList.sort((a, b) => {
    const slugA = a.tagSlug
    const slugB = b.tagSlug

    if (slugA < slugB) {
      return -1
    } else if (slugA > slugB) {
      return 1
    } else {
      return 0
    }
  }).map(tag => {
    const name = tag.tagName
    const slug = tag.tagSlug
    return (
      <li key={slug}>
        <Link to={`/tags/${slug}`}>
          {name}
        </Link>
      </li>
    )
  })

  return (
    <TagsWrapper>
      <p>Tags: </p>
      <ul>
        {tagItems}
      </ul>
    </TagsWrapper>
  )
}

export default TagList