import React from 'react'

import NextLink from './nextLink'
import PrevLink from './prevLink'

import PaginationStyle from '../styles/pagination'

const PaginationContainer = ({ next, prev }) => {
  return (
    <PaginationStyle>
      {
        prev && <PrevLink
          title={prev.title}
          route={prev.route} />
      }
      {
        next && <NextLink
          title={next.title}
          route={next.route} />
      }
    </PaginationStyle>
  )
}

export default PaginationContainer